import {combineReducers} from "redux";
import {reducer as form} from "redux-form";

import {
  CHANGE_LANGUAGE,
  CLOSE_ADVANCED_SEARCH,
  CLOSE_DISCLAIMER_MODAL,
  CLOSE_NEWS_MESSAGE,
  FetchAttachment,
  FetchEnv,
  FetchPDF,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCESS,
  NEWS_IS_RENDERED,
  NEWS_POLLING_STARTED,
  OPEN_ADVANCED_SEARCH,
  OPEN_DISCLAIMER_MODAL,
  PAGE_NEWS,
  RECEIVE_CATEGORIES,
  RECEIVE_INFO_TEXT,
  RECEIVE_ISSUERS,
  RECEIVE_MARKETS,
  RECEIVE_NEWS,
  RECEIVE_NEWS_MESSAGE,
  RECEIVE_NEWS_QUERY,
  REQUEST_CATEGORIES,
  REQUEST_ISSUERS,
  REQUEST_MARKETS,
  REQUEST_NEWS,
  REQUEST_NEWS_MESSAGE,
  REQUEST_NEWS_QUERY,
  RESET_DAY_PICKER_FALSE,
  RESET_DAY_PICKER_TRUE,
  SHOW_ERROR_MESSAGE,
  STOPPING_NEWS_POLLING,
  UPDATE_IS_ACTIVE_ISSUERS
} from "../actions/actions.js";
import {Maintenance} from "../CommonActions";

function login(
  state = {
    isLoggingIn: false,
    isLoggedIn: false
  },
  action
) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isLoggingIn: true
      });
    case LOGIN_SUCESS:
      return Object.assign({}, state, {
        isLoggingIn: false,
        isLoggedIn: true
      });
    case LOGIN_FAIL:
      return Object.assign({}, state, {
        isLoggingIn: false,
        isLoggedIn: false
      });
    default:
      return state;
  }
}

function category(
  state = {
    isFetchingCategory: false,
    categoriesIsFetched: false,
    categories: []
  },
  action
) {
  switch (action.type) {
    case REQUEST_CATEGORIES:
      return Object.assign({}, state, {
        isFetchingCategory: true
      });
    case RECEIVE_CATEGORIES:
      return Object.assign({}, state, {
        isFetchingCategory: false,
        categoriesIsFetched: true,
        categories: action.items.categories
      });

    default:
      return state;
  }
}

function issuer(
  state = {
    isFetchingIssuer: false,
    issuersIsFetched: false,
    mapIssuers: new Map(),
    isActive: 1 // Default value is 1==Active. The other value is 0==Not Active
  },
  action
) {
  switch (action.type) {
    case REQUEST_ISSUERS:
      return Object.assign({}, state, {
        isFetchingIssuer: true
      });
    case RECEIVE_ISSUERS:
      return Object.assign({}, state, {
        isFetchingIssuer: false,
        issuersIsFetched: true,
        mapIssuers: new Map(
          action.items.issuers.map(issuer => {
            return [
              issuer.id + "_" + issuer.issuerId,
              {
                name: issuer.name,
                isActive: issuer.isActive,
                id: issuer.issuerId
              }
            ];
          })
        )
      });
    case UPDATE_IS_ACTIVE_ISSUERS:
      return Object.assign({}, state, {
        isActive: action.isActive
      });

    default:
      return state;
  }
}

function market(
  state = {
    isFetchingMarkets: false,
    marketsIsFetched: false,
    mapMarkets: new Map()
  },
  action
) {
  switch (action.type) {
    case REQUEST_MARKETS:
      return Object.assign({}, state, {
        isFetchingMarkets: true
      });
    case RECEIVE_MARKETS:
      return Object.assign({}, state, {
        isFetchingMarkets: false,
        marketsIsFetched: true,
        mapMarkets: new Map(
          action.items.markets.map(market => {
            return [market.symbol, market];
          })
        )
      });
    default:
      return state;
  }
}

function news(
  state = {
    isFetchingNews: false,
    newsIsFetched: false,
    newsIsUpdated: false,
    newsRows: [],
    overflow: false
  },
  action
) {
  switch (action.type) {
    case REQUEST_NEWS_QUERY:
    case REQUEST_NEWS:
      return Object.assign({}, state, {
        isFetchingNews: true,
        newsIsFetched: false
      });
    case RECEIVE_NEWS_QUERY:
    case RECEIVE_NEWS:
      return Object.assign({}, state, {
        isFetchingNews: false,
        newsIsFetched: true,
        newsIsUpdated: true,
        newsRows: action.news,
        overflow: action.overflow
      });
    case NEWS_IS_RENDERED:
      return Object.assign({}, state, {
        newsIsUpdated: false
      });
    case SHOW_ERROR_MESSAGE:
      return Object.assign({}, state, {
        isFetchingNews: false,
        newsIsUpdated: false
      });

    default:
      return state;
  }
}

function newsPoller(
  state = {
    isPolling: false,
    pollerID: -1
  },
  action
) {
  switch (action.type) {
    case NEWS_POLLING_STARTED:
      return Object.assign({}, state, {
        isPolling: true,
        pollerID: action.pollerID
      });
    case STOPPING_NEWS_POLLING:
      return Object.assign({}, state, {
        isPolling: false,
        pollerID: -1
      });
    default:
      return state;
  }
}

function advancedSearchToggler(
  state = {
    isOpen: false
  },
  action
) {
  switch (action.type) {
    case OPEN_ADVANCED_SEARCH:
      return Object.assign({}, state, {
        isOpen: true
      });
    case CLOSE_ADVANCED_SEARCH:
      return Object.assign({}, state, {
        isOpen: false
      });
    default:
      return state;
  }
}

function dayPickerReset(
  state = {
    isReset: false
  },
  action
) {
  switch (action.type) {
    case RESET_DAY_PICKER_TRUE:
      return Object.assign({}, state, {
        isReset: true
      });
    case RESET_DAY_PICKER_FALSE:
      return Object.assign({}, state, {
        isReset: false
      });
    default:
      return state;
  }
}

function newsMessage(
  state = {
    isFetchingNewsMessage: false,
    fetchingMessage: false,
    newsMessageIsFetched: false,
    isOpen: false,
    textFormat: "",
    newsMessage: {}
  },
  action
) {
  switch (action.type) {
    case RECEIVE_NEWS_MESSAGE:
      return Object.assign({}, state, {
        fetchingMessage: false,
        newsMessageIsFetched: true,
        isOpen: true,
        newsMessage: action.newsMessage,
        textFormat: action.textFormat
      });
    case REQUEST_NEWS_MESSAGE:
      return Object.assign({}, state, {
        fetchingMessage: true
      });
    case CLOSE_NEWS_MESSAGE:
      return Object.assign({}, state, {
        isOpen: false
      });
    default:
      return state;
  }
}

function language(
  state = {
    language: "nb"
  },
  action
) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return Object.assign({}, state, {
        language: state.language === "nb" ? "en" : "nb"
      });
    default:
      return state;
  }
}

function disclaimerToggler(
  state = {
    isOpen: false
  },
  action
) {
  switch (action.type) {
    case OPEN_DISCLAIMER_MODAL:
      return Object.assign({}, state, {
        isOpen: true
      });
    case CLOSE_DISCLAIMER_MODAL:
      return Object.assign({}, state, {
        isOpen: false
      });
    case REQUEST_NEWS:
      return Object.assign({}, state, {
        isOpen: false
      });
    case REQUEST_NEWS_MESSAGE:
      return Object.assign({}, state, {
        isOpen: false
      });
    case NEWS_IS_RENDERED:
      return Object.assign({}, state, {
        isOpen: false
      });
    default:
      return state;
  }
}

function error(
  state = {
    errorHasOccured: false,
    errorMessage: ""
  },
  action
) {
  switch (action.type) {
    case SHOW_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorHasOccured: true,
        errorMessage: action.errorMessage
      });
    case CLOSE_ADVANCED_SEARCH:
    case OPEN_ADVANCED_SEARCH:
    case REQUEST_NEWS:
    case REQUEST_NEWS_MESSAGE:
    case RECEIVE_NEWS_MESSAGE:
    case RECEIVE_NEWS:
      return !state.errorHasOccured
        ? state
        : Object.assign({}, state, {
            errorHasOccured: false,
            errorMessage: ""
          });
    default:
      return state;
  }
}

function infoTextFromServer(
  state = {
    dataEN: "",
    dataNB: ""
  },
  action
) {
  switch (action.type) {
    case RECEIVE_INFO_TEXT:
      return Object.assign({}, state, {
        dataEN: action.dataEN,
        dataNB: action.dataNB
      });
    default:
      return state;
  }
}

function pager(
  state = {
    activePage: 1,
    maxResultOnPage: 50
  },
  action
) {
  switch (action.type) {
    case PAGE_NEWS:
      return Object.assign({}, state, {
        activePage: action.activePage
      });
    default:
      return state;
  }
}

export const envJson = (
    state = {
      env: {},
    },
    action
) => {
  if (action.type === FetchEnv.receive) {
    return Object.assign({}, state, {
      env: action.items.env,
    });
  } else {
    return state;
  }
};

export const getAttachment = (
    state = {
      file: undefined,
    },
    action
) => {
  if (action.type === FetchAttachment.receive) {
    return Object.assign({}, state, {
      file: action.payload,
    });
  } else {
    return state;
  }
};

export const getPDF = (
    state = {
      file: undefined,
    },
    action
) => {
  if (action.type === FetchPDF.receive) {
    return Object.assign({}, state, {
      file: action.payload,
    });
  } else {
    return state;
  }
};

export const maintenance = (
    state = {
      maintenance: false,
      norwegianText: undefined,
      englishText: undefined
    },
    action
) => {
  switch (action.type) {
    case Maintenance.setMaintenance:
      return Object.assign({}, state, {
        maintenance: action.data.maintenance,
        norwegianText: action.data.norwegianMaintenanceText,
        englishText: action.data.englishMaintenanceText
      });
    default:
      return state;
  }
}

const root = combineReducers({
  login,
  category,
  issuer,
  market,
  news,
  newsPoller,
  advancedSearchToggler,
  disclaimerToggler,
  newsMessage,
  language,
  error,
  infoTextFromServer,
  pager,
  dayPickerReset,
  form: form,
  envJson,
  maintenance,
});

export default root;
