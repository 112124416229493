import React from "react";

import PropTypes from "prop-types";
import AdvancedSearchContainer from "../containers/AdvancedSearchContainer";
import StyledApp from "./styled-components/StyledApp";
import NewsListContainer from "../containers/NewsListContainer";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {change} from "redux-form";
import {Route, Switch, withRouter} from "react-router-dom";
import MenuActionContainer from "../containers/MenuActionContainer";
import moment from "moment";
import FooterContainer from "../containers/FooterContainer";
import {Disclaimer} from "./Disclaimer";
import DisclaimerContainer from "../containers/DisclaimerContainer";

import {parse} from "query-string";
import {
  changeLanguage,
  closeDisclaimerModal,
  fetchCategories,
  fetchEnv,
  fetchInfoTextFromServer,
  fetchIssuers,
  fetchMarkets,
  fetchNews,
} from "../actions/actions";

import i18n from "../i18n";
import {HtmlElements} from "./styled-components/HtmlElements";
import {withTranslation} from "react-i18next";
import NewsMessageContainer from "../containers/NewsMessageContainer";
import {getLocalLanguage} from "../js/utils";
import {getMaintenance} from "../obsvcClient";
import {setMaintenance} from "../CommonActions";

class App extends React.Component {
  constructor(props) {
    super(props);
    // Flag to indicate if the user is coming from an query url. If so, we don't fetch
    // default news on mount.
    this.fromQuery = false;
    this.config = {};

    // Flag do indicate if a query has been processed. This is to prevent a loop since the query
    // will trigger a language action, and this component listens to a language action.
    this.queryHasBeenProcessed = false;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.config && nextProps.config !== this.config) {
      this.config = nextProps.config;
      if (nextProps.config.languages && nextProps.config.languages.length === 1) {
            i18n.changeLanguage(nextProps.config.languages[0]);
          }
    }

  }

  componentDidMount() {
    if (getLocalLanguage()) {
      i18n.changeLanguage(getLocalLanguage());
    }
    const parsedParams = parse(this.props.location.search);

    const NOT_IN_USE = "NOT_IN_USE";

    const mapOldParamNameToNewName = (key) => {
      const paramNameIsOnNewFormat = (name) =>
        [
          "market",
          "fromDate",
          "toDate",
          "category",
          "issuer",
          "messageTitle",
          "test",
        ].includes(name);

      switch (key) {
        case "parsedParams.headerSearch":
          return "messageTitle";
        case "searchCriteria.issuerSign":
          return "issuer";
        case "searchCriteria.categoryId":
          return "category";
        case "searchCriteria.fromDate":
          return "fromDate";
        case "searchCriteria.toDate":
          return "toDate";
        case "searchCriteria.exchangeCode":
          return "market";
        default:
          return paramNameIsOnNewFormat(key) ? key : NOT_IN_USE;
      }
    };

    const convertOldParamObjectToNewMap = (params) => {
      return new Map(
        Object.keys(params)
          .filter(
            (key) =>
              mapOldParamNameToNewName(key) !== NOT_IN_USE &&
              params[key].length > 0 &&
              params[key] !== "0"
          )
          .map((key) => [mapOldParamNameToNewName(key), params[key]])
      );
    };

    const convertOldDateFormatToNewFormat = (params, paramName) => {
      var newMap = new Map(params);
      if (
        params.has(paramName) &&
        moment(params.get(paramName), "DD.MM.YYYY", true).isValid()
      ) {
        const elements = moment(params.get(paramName), "DD.MM.YYYY", true);
        newMap.set(
          paramName,
          elements.format("YYYY") +
            "-" +
            elements.format("MM") +
            "-" +
            elements.format("DD")
        );
      }
      return newMap;
    };

    const updateFormFieldsWithParamValues = (params) => {
      params.forEach((value, key) => {
        if (key === "fromDate" || key === "toDate") {
          const elements = moment(value, "YYYY-MM-DD", true);
          if (elements.isValid()) {
            const dateString =
              elements.format("DD") +
              "." +
              elements.format("MM") +
              "." +
              elements.format("YYYY");
            this.props.dispatch(change("advancedSearch", key, dateString));
          }
        } else this.props.dispatch(change("advancedSearch", key, value));
      });
    };

    const createURLString = (params) => {
      var url = [];
      for (var [key, value] of params)
        url.push(encodeURI(key) + "=" + encodeURI(value));
      return url.join("&");
    };

    if (parsedParams.languageId != null && parsedParams.languageId === "nb") {
      i18n.changeLanguage("nb");
      this.props.dispatch(changeLanguage());
    }

    var params = convertOldParamObjectToNewMap(parsedParams);

    params = convertOldDateFormatToNewFormat(params, "fromDate");
    params = convertOldDateFormatToNewFormat(params, "toDate");

    updateFormFieldsWithParamValues(params);

    const urlString = createURLString(params);

    const handleEnvData = (envEntity) => {
      if (envEntity.maintenance) {
        this.props.dispatch(setMaintenance({maintenance: envEntity.maintenance, norwegianMaintenanceText: envEntity.norwegianMaintenanceText, englishMaintenanceText: envEntity.englishMaintenanceText}));
      } else {
        if (!this.fromQuery) {
          this.props.dispatch(fetchNews(urlString));
          if (urlString.length > 0) this.props.history.push("/search?" + urlString);
        }

        this.props.dispatch(fetchInfoTextFromServer());
        this.props.dispatch(fetchMarkets());
        this.props.dispatch(fetchCategories());
        this.props.dispatch(fetchIssuers());
      }
    }

    this.props.dispatch(fetchEnv())
        .then((res) => handleEnvData(res.env))
        .catch((err) => {
          console.log(err);
          this.props.dispatch(fetchEnv())
            .then((res) => handleEnvData(res.env))
            .catch((err) => {
              console.log(err);
              getMaintenance()
                  .then(res => {
                    if (res.maintenance) {
                      this.props.dispatch(setMaintenance(res));
                    } else {
                      this.props.dispatch(setMaintenance({maintenance: true}));
                    }
                  });
            });
        });
  }

  render() {
    const props = this.props;
    const { t, maintenance } = props;

    const resetPage = () => {
      window.location.assign("/");
    };

    return [
      <StyledApp.AppGrid
        advancedSearchIsOpen={props.advancedSearchIsOpen}
        key={"main_"}
      >
        <Helmet htmlAttributes={{ lang: i18n.language || "nb" }}>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <link
            href="//fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,700italic,400,500,600,700"
            rel="stylesheet"
            type="text/css"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
          />
        </Helmet>
        <StyledApp.HeaderGrid
          styles={{ background: "white" }}
          role="header"
          isTestEnvironment={props.config.testEnvironment}
        >
          <StyledApp.HeaderLeft
            isTestEnvironment={props.config.testEnvironment}
          >
            <HtmlElements.FocusElement onKeyDown={resetPage}>
              <React.Fragment>
                {props.config.logoName ? (
                  <StyledApp.HeaderLogoDesktop
                    onClick={resetPage}
                    src={"/icons/" + props.config.logoName}
                    alt="logo"
                  />
                ) : (
                  <StyledApp.HeaderLogoDesktop
                    onClick={resetPage}
                    width="262px"
                    height="90px"
                    src="/icons/Oslo-Børs_Logo-RGB_Reverse.svg"
                    alt="logo"
                  />
                )}
                <StyledApp.HeaderLogoTablet
                  onClick={resetPage}
                  width="207px"
                  height="65px"
                  src={
                    props.config.logoName
                      ? "/icons/" + props.config.logoName
                      : "/icons/Oslo-Børs_Logo-RGB_Reverse.svg"
                  }
                  alt="logo"
                />
                <StyledApp.HeaderLogoPhone
                  onClick={resetPage}
                  width="96px"
                  height="24px"
                  src={
                    props.config.logoName
                      ? "/icons/" + props.config.logoName
                      : "/icons/Oslo-Børs_Logo-RGB_Reverse.svg"
                  }
                  alt="logo"
                />
              </React.Fragment>
            </HtmlElements.FocusElement>
          </StyledApp.HeaderLeft>
          <StyledApp.HeaderCenter
            isTestEnvironment={props.config.testEnvironment}
          >
            <HtmlElements.FocusElement onKeyDown={resetPage}>
              <StyledApp.Heading
                alt="NewsWeb"
                src="/icons/NewsWeb.svg"
                onClick={resetPage}
              />
              {props.config.testEnvironment ? "test" : ""}
            </HtmlElements.FocusElement>
          </StyledApp.HeaderCenter>
          <StyledApp.HeaderRight
            isTestEnvironment={props.config.testEnvironment}
          >
            <MenuActionContainer />
          </StyledApp.HeaderRight>
        </StyledApp.HeaderGrid>

        {
          !maintenance.maintenance && this.props.issuersIsFetched &&
            <StyledApp.AdvancedSearchPanel
              isOpen={props.advancedSearchIsOpen}
              key={"searchPanel"}
              role="search"
            >
              <AdvancedSearchContainer />
            </StyledApp.AdvancedSearchPanel>
        }
        <StyledApp.NewsPanel
          displayPanelOnMobile={!props.advancedSearchIsOpen}
          key={"newsPanel"}
          role="main"
        >
          <StyledApp.ErrorBox
            displayError={props.errorHasOccured}
            advancedSearchIsOpen={props.advancedSearchIsOpen}
            role="alert"
            id="date_error"
          >
            <StyledApp.ErrorHeader>
              {props.t("something_went_wrong")}
            </StyledApp.ErrorHeader>
          </StyledApp.ErrorBox>
          <StyledApp.ErrorBox
              displayError={maintenance.maintenance}
              advancedSearchIsOpen={props.advancedSearchIsOpen}
              role="alert"
              id="maintenance"
          >
            <StyledApp.ErrorHeader>
              {props.t("maintenance_header")}
            </StyledApp.ErrorHeader>
            {i18n?.language?.toLocaleLowerCase() === "nb" && maintenance.norwegianText && <StyledApp.ErrorBody>{maintenance.norwegianText}</StyledApp.ErrorBody>}
            {i18n?.language?.toLocaleLowerCase() === "en" && maintenance.englishText && <StyledApp.ErrorBody>{maintenance.englishText}</StyledApp.ErrorBody>}
          </StyledApp.ErrorBox>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <NewsListContainer history={this.props.history} />}
            />
            <Route
              path="/search"
              render={() => <NewsListContainer history={this.props.history} />}
            />
            <Route
              path="/message/:id"
              render={(props) => (
                <NewsMessageContainer
                  {...props}
                  onlyPDFIcon={this.props.config.onlyPDFIcon}
                />
              )}
            />
            <Route path="/announcement/attachment" render={() => undefined} />
            <Route
              path="/announcement/:id"
              render={(props) => (
                <NewsMessageContainer
                  useClientAnnouncementId
                  {...props}
                  onlyPDFIcon={this.props.config.onlyPDFIcon}
                />
              )}
            />
            <Route path="/newsweb/" render={() => <newswebHandler />} />
            <Route path="/index.jsp" render={() => <newswebHandler />} />
            <Route path="/search.do" render={() => <newswebHandler />} />
            <Route path={"/query.jsp"} render={() => <queryHandler />} />
            <Route
              exact
              path="/disclaimer"
              render={() => (
                <DisclaimerContainer
                  onClick={() => resetPage()}
                  isOpen={props.advancedSearchIsOpen}
                />
              )}
            />
            <Route
              path={"/*"}
              render={() => <notFoundHandler />}
              status={404}
            />
          </Switch>
        </StyledApp.NewsPanel>

        <StyledApp.DisclaimerBox
          id="disclaimerPanel"
          onClick={(e) => {
            if (["disclaimerPanel", "closeIcon"].includes(e.target.id))
              this.props.dispatch(closeDisclaimerModal());
          }}
          isOpen={props.disclaimerIsOpen}
        >
          <Disclaimer />
        </StyledApp.DisclaimerBox>
      </StyledApp.AppGrid>,
      <StyledApp.FooterGrid
        isTestEnvironment={props.config.testEnvironment}
        key="footer"
        displayPanel={!props.advancedSearchIsOpen}
        role="footer"
      >
        <StyledApp.FooterLeft>
          <StyledApp.FooterInfoLink
            href={this.props.infoData[i18n.language || "nb"].url}
          >
            {this.props.infoData[i18n.language || "nb"].text}
          </StyledApp.FooterInfoLink>
        </StyledApp.FooterLeft>
        <StyledApp.FooterCenter>
          <StyledApp.FooterLink
            target="_blank"
            href={
              i18n.language === "nb"
                ? "https://www.euronext.com/nb/markets/oslo"
                : "https://www.euronext.com/en/markets/oslo"
            }
          >
            {this.props.config.homepageName ? this.props.config.homepageName : t("venue_name")}
          </StyledApp.FooterLink>
        </StyledApp.FooterCenter>
        <StyledApp.FooterRight>
          <FooterContainer />
        </StyledApp.FooterRight>
      </StyledApp.FooterGrid>,
    ];
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
  const {
    advancedSearchToggler,
    disclaimerToggler,
    error,
    infoTextFromServer,
    envJson,
    maintenance,
    issuer,
  } = state;

  return {
    advancedSearchIsOpen: advancedSearchToggler.isOpen,
    disclaimerIsOpen: disclaimerToggler.isOpen,
    errorMessage: error.errorMessage,
    errorHasOccured: error.errorHasOccured,
    infoData: { en: infoTextFromServer.dataEN, nb: infoTextFromServer.dataNB },
    config: envJson.env,
    maintenance: maintenance,
    issuersIsFetched: issuer.issuersIsFetched,
  };
}

export default withRouter(
  connect(mapStateToProps)(withTranslation("translations")(App))
);
